import { Outlet, Link } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react"
import axios from 'axios';

const color1 = "bg-[#7D8F69]";
const color2 = "bg-[#557153]";
const color3 = "bg-[#A9AF7E]";
const color4 = "bg-sky-50";
const color5 = "bg-blue-100";
const color6 = "bg-[#E6E5A3]";
const color7 = "bg-white";

var path;


const IP = 'www.bustlanga.lt';

const Layout = () => {
    path = window.location.pathname.split("/")[1] == "product" ? "../" : ""; 
    if(path != "../"){
        var arr = window.location.pathname.split("/");
        path = (arr[1] == "products") ? "../" : ""; 
    }
    if(path != "../"){
        var arr = window.location.pathname.split("/");
        path = (arr[1] == "subcategory") ? "../" : ""; 
    }
    
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [formattedList, setFormattedList] = useState([]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const closeMenu = () =>{
        setIsMenuOpen(false);
    };

    const toggleDropdown = () =>{
        setDropdownOpen(!dropdownOpen);
    };
    const closeDropdown = () =>{
        setDropdownOpen(false);
    };

    useEffect(() => {
        fetchData();
    }, []);
    
    const fetchData = () => {
        axios.get(`https://${IP}/api/categories/`)
            .then(response => {
                var splitString;
                try{
                    const list = response.data; 
                    splitString = list.split('}{');
                    for(var i = 0; i < splitString.length; i++){
                        splitString[i] = splitString[i].replaceAll("'", '"');
                        if(splitString.length !== 1){
                            if(i === 0 && splitString.length != 1){
                                splitString[i] = splitString[i] + '}';
                            }
                            else if(i === splitString.length - 1 && splitString.length != 1){
                                splitString[i] = '{' + splitString[i];
                            }
                            else{
                                splitString[i] = '{' + splitString[i] + '}';
                            }
                            splitString[i] = JSON.parse(splitString[i]);
                        }
                        else{
                            splitString[i] = JSON.parse(splitString[i]);
                        }
                    }
                }
                catch(err){
                    // console.log(err);
                }

                splitString = splitString.filter((item) => item.rodyti_sarase == 1);

                const formattedList = splitString.map(item => (
                    <li className={"block px-4 py-2 hover:" + color3 + " "}><Link reloadDocument onClick={() => {closeDropdown();closeMenu();}} to={"products/" + item.kategorija}>{item.kategorija}</Link></li>
                ));
                setFormattedList(formattedList);    
            })
            .catch(error => {
                console.log("Failed to fetch data. Reload page.");
        });
    };

    return (
        <>
            <nav className={"top-0 left-0 w-full z-header transition-header fixed shadow-lg backdrop-blur-lg border-b-4 border-yellow-500 " + color2 + " "}>
                <div className="flex flex-wrap items-center justify-between mx-auto p-8 text-xl">
                    <div className="flex items-center p-4">
                        <Link to="/" onClick={() => {closeDropdown();closeMenu();}} className="text-white text-3xl font-semibold">Bustlanga</Link>
                    </div>
                    <button onClick={toggleMenu} type="button" className={"inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:" + color3 + " focus:outline-none focus:ring-2 focus:ring-gray-200"} aria-controls="navbar-default" aria-expanded="false">
                        <span className="sr-only">Atidaryti meniu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                        </svg>
                    </button>
                    <div className={`${isMenuOpen ? 'block w-full flex p-3 justify-center' : 'hidden'}   md:w-auto md:flex md:block" id="navbar-default`}>
                        <ul className={`${isMenuOpen ? 'p-2' : ''} w-full font-medium text-xl flex flex-col p-4 mt-4 md:flex-row md:space-x-8 rtl:space-x-reverse md:text-white md:border-0 md:p-4`}>
                            <li><Link onClick={toggleDropdown} className={`${isMenuOpen ? 'm-2 text-gray-100 p-2 border-b-2 border-yellow-500 ' : ' '} block py-2 px-3 md:text-white md:hover:bg-transparent md:border-b-2 md:hover:text-cyan-400 md:p-2 md:border-yellow-500 hover:border-cyan-400`}>Produktai<img height="22px" width="22px" className="inline" src={path + "dropdown.png"}/></Link>
                                <div className={`${dropdownOpen ? 'fixed inline' : 'hidden'} z-20 ${color7} text-black divide-y divide-gray-100 rounded-sm shadow`}>    
                                    <ul className="py-2 text-sm text-gray-700">
                                        <li className={`block px-4 py-2 hover:${color5}`}><Link reloadDocument onClick={() => {closeDropdown();closeMenu();}} to={"products/"}>Visi produktai</Link></li>
                                        {formattedList}
                                    </ul>
                                </div>
                            </li>
                            <li><Link to="/news" onClick={closeMenu} className={`${isMenuOpen ? 'm-2 text-gray-100 p-2 border-b-2 border-yellow-500 ' : ''} block py-2 px-3 md:text-white hover:${color3} md:hover:bg-transparent md:border-b-2 md:hover:text-cyan-400 md:p-2 md:border-yellow-500 hover:border-cyan-400`}>Naujienos</Link></li>
                            <li><Link to="/contacts" onClick={closeMenu} className={`${isMenuOpen ? 'm-2 text-gray-100 p-2 border-b-2 border-yellow-500 ' : ''} block py-2 px-3 md:text-white hover:${color3} md:hover:bg-transparent md:border-b-2 md:hover:text-cyan-400 md:p-2 md:border-yellow-500 hover:border-cyan-400`}>Kontaktai</Link></li>
                        </ul>
                    </div>
                </div>
                
            </nav>
            <Outlet />
        </>
    )
};
export default Layout;