import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
const HtmlToReactParser = require('html-to-react').Parser;


const IP = 'www.bustlanga.lt';

const color1 = "bg-[#7D8F69]";
const color2 = "bg-[#557153]";
const color3 = "bg-[#A9AF7E]";
const color4 = "bg-sky-50";
const color5 = "bg-blue-100";
const color6 = "bg-[#E6E5A3]";
const color7 = "bg-white";

const htmlToReactParser = new HtmlToReactParser();


const ProductsFiltered = () => {
    const category = window.location.pathname.split("/").pop();
    const [formattedMenu, setFormattedMenu] = useState([]);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(0);
    const [isBottom, setIsBottom] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const noMore = false;
    var prevResponse;

    useEffect(() => {
        fetchCats();
        fetchData();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setIsBottom(window.scrollY + window.innerHeight >= document.body.scrollHeight - 5);
    };

    useEffect(() => {
        if (!isLoading && isBottom) {
            fetchData();
        }
    }, [isBottom]);

    const fetchCats = () => {
        axios.get(`https://${IP}/api/subcategories/${category}/`)
            .then(response => {
                var splitString = response.data.split("| ");
                splitString.forEach((item, index, arr) => { arr[index] = item.replaceAll("'", '"') })
                splitString.forEach((item, index, arr) => { arr[index] = JSON.parse(item) })
                const formattedMenu = splitString.filter((item) => { return item.subcategory !== "Be subkategorijos" }).map(item => (
                    <Link to={`/subcategory/${item.subcategory}`} className={`hover:bg-green-100 p-1`} key={item.id}>{item.subcategory}</Link>
                ));
                setFormattedMenu(formattedMenu);
            })
            .catch(error => {
                console.log("Failed to fetch data. Reload page.");
                setItems(<div className={`pr-12 pl-12 2xl:pr-32 2xl:pl-32 pt-36 sm:pt-36 md:pt-48 min-h-screen flex justify-center flex-col items-stretch`}>
                    <div className={" " + color2 + " bg-opacity-50 p-8 border-t-2 border-b-2 sm:rounded-md shadow-lg text-gray-800 sm:border-2 border-gray-400 w-full xl:w-2/5 m-auto"}>
                        <p className="text-xl font-extrabold leading-tight text-white text-center">Šioje kategorijoje produktų nerasta...</p>
                    </div>
                </div>);
            });
    }
    const fetchData = () => {
        if (noMore)
            return [];
        setIsLoading(true);
        document.title = `${decodeURIComponent(category)}`;
        axios.get(`https://${IP}/api/products/${category}/${page}/`)
            .then(response => {
                var splitString;
                try {
                    const list = response.data;
                    splitString = list.split('}{');
                    for (var i = 0; i < splitString.length; i++) {
                        splitString[i] = splitString[i].replaceAll("'", '"');
                        if (splitString.length !== 1) {
                            if (i === 0 && splitString.length != 1) {
                                splitString[i] = splitString[i] + '}';
                            }
                            else if (i === splitString.length - 1 && splitString.length != 1) {
                                splitString[i] = '{' + splitString[i];
                            }
                            else {
                                splitString[i] = '{' + splitString[i] + '}';
                            }
                            splitString[i] = JSON.parse(splitString[i]);
                        }
                        else {
                            splitString[i] = JSON.parse(splitString[i]);
                        }
                    }
                }
                catch (err) {
                    console.log(err);
                }

                if (splitString[0] == "") {
                    setIsLoading(false);
                    return;
                }


                splitString.forEach((item) => {
                    item.desc = item.desc.replaceAll("\\&", '"');
                    item.desc = item.desc.replaceAll("\\n", '<br>');
                    item.desc = item.desc.replaceAll("\\r", '');
                    item.desc = item.desc.replaceAll("\\t", ' ');
                });

                const formattedList = splitString.map(item => (

                    <div className={`mx-6 w-full xl:w-auto xl:flex xl:flex-row border-t-4 border-yellow-500 xl:h-96 md:rounded overflow-hidden md:shadow-lg ${color7} m-12 mr-0 ml-0 sm:m-12`} key={item.id} id={item.id}>
                        <div className='xl:w-1/3 w-full bg-gray-300 '>
                            <img class="object-contain xl:h-full w-full xl:w-auto m-auto" onClick={() => { const w = window.open(`${item.photo !== 0 ? `${item.photo}` : "/interrogation.png"}`) }} src={item.photo !== 0 ? `${item.photo}` : "interrogation.png"} alt="Product" />
                        </div>

                        <div className='xl:flex xl:flex-col xl:justify-between xl:w-2/3'>
                            <div class="px-6 py-4">
                                <Link to={`/product/${item.id}`} class="font-bold text-xl mb-2">{item.name}</Link>
                                <p class="text-gray-700 text-md">{htmlToReactParser.parse(item.desc)}</p>
                            </div>
                            <div class="py-4 w-full flex xl:justify-end justify-center">
                                <Link to={`/products/${item.category}`} class="bg-gray-200 rounded-full px-3 py-1 m-2 text-sm font-semibold text-yellow-400 bg-gray-600">{item.category}</Link>
                                {item.subcategory !== "Be subkategorijos" ? <Link to={`/subcategory/${item.subcategory}`} class="inline-block bg-gray-200 rounded-full px-3 py-1 m-2 text-sm font-semibold text-yellow-400 bg-gray-600">{item.subcategory}</Link> : null}
                                <Link to={`/product/${item.id}`} class="px-3 py-1 m-2 text-sm font-semibold text-green-700 ">Išsamiau</Link>
                            </div>
                        </div>

                    </div>
                ));
                if (formattedList.length == 0) {
                    noMore = true;
                }
                else {
                    if (page == 0) {
                        setItems([formattedList]);
                    }
                    else
                        setItems(prevItems => [...prevItems, formattedList]);
                    setPage(prevPage => prevPage + 6);
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                console.log("Failed to fetch data. Reload page.");
            });
    };
    return (
        <div className={`pt-48 ${color1} min-h-screen items-stretch`}>
            <div className='m-auto xl:ml-24 xl:mr-24 m-0 bg-gray-200 flex flex-col 2xl:flex-row min-h-screen'>
                <div className='flex flex-row p-12 w-full 2xl:w-2/12 bg-gray-300 align-center'>
                    <div class="w-full">
                        <label for="category" class="m-auto block text-sm font-medium text-gray-700 mb-1">Pasirinkti kategoriją:</label>
                        <div className='overflow-y-auto h-36 2xl:h-auto flex flex-col w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded shadow leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-wrap'>
                            {formattedMenu}
                        </div>
                    </div>
                </div>
                <div className='flex flex-row xl:flex-col flex-wrap w-full 2xl:w-10/12'>
                    {items}
                    <div className={`flex justify-center w-full m-auto pt-12 z-20 ${isLoading == true ? "" : "hidden"} `}>
                        <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-700" viewBox="0 0 100 101" fill="none" xmlns="https://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                    </div>
                </div>

            </div>
        </div>
    );


}

export default ProductsFiltered;