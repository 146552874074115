import { Link } from "react-router-dom";

const color1 = "bg-[#7D8F69]";
const color2 = "bg-[#557153]";
const color3 = "bg-[#A9AF7E]";
const color4 = "bg-sky-50";
const color5 = "bg-blue-100";
const color6 = "bg-[#E6E5A3]";
const color7 = "bg-white";

const Empty = () => {
  return (
    <div className={color2 + " h-screen flex items-center justify-center"}>
      <div className={"relative " + color1 + " bg-opacity-50 p-8 border-t-2 border-b-2 sm:rounded-md shadow-lg text-gray-800 sm:border-2 border-gray-400"}>
        <p className="text-3xl font-extrabold leading-tight text-gray-100 ">Toks puslapis neegzistuoja...</p>
        <p className="text-sm mt-4">
          <Link to="/" className={"text-gray-100 hover:underline"}>Grįžti</Link>
        </p>
      </div>
    </div>
  );
}

export default Empty;