import { Link } from "react-router-dom";
import { useEffect } from 'react';

const color1 = "bg-[#7D8F69]";
const color2 = "bg-[#557153]";
const color3 = "bg-[#A9AF7E]";
const color4 = "bg-sky-50";
const color5 = "bg-blue-100";
const color6 = "bg-[#E6E5A3]";
const color7 = "bg-white";

const About = () => {
    useEffect(()=>{
      document.title = 'Bustlanga - Langai, durys, nameliai';
  }, []);
  return (
    <div className="pt-12 md:pt-32 flex flex-col min-h-screen antialiased overflow-x-hidden overflow-y-auto">
      <section class="md:min-h-screen bg-cover bg-center flex items-center justify-center" style={{
        backgroundImage: `url("https://www.bustlanga.lt/PROJEKTAS 1-A.webp")`
      }}>
        <div class=" xl:w-1/3 w-full h-screen m-auto  text-center bg-black bg-opacity-60 xl:bg-opacity-80 pt-16 xl:pt-32 p-12 xl:pt-32 hover:bg-opacity-90 transition duration-500 flex items-center ">
          <div className="p-3 lg:p-12">
            <h1 class="text-4xl font-bold text-white ">Namelis - variantas 1</h1>
            <p class="mt-4 text-white text-start m-auto text-md md:text-lg/8 xl:text-md/8 2xl:text-2xl/10 ">Namas pilnai paruoštas gyventi iš karto po prijungimo prie komunikacijų.
              <div className="m-4 mt-0 line-clamp-6">
                <ul className="text-md md:text-lg/8 xl:text-md/8 2xl:text-2xl/10 ">
                  <li>- puiki kaina iš gamintojo;</li>
                  <li>- vengiant ilgų namo užbaigimo terminų;</li>
                  <li>- namas pilnai paruoštas gyventi, į kurį taip pat įeina: vandentiekis, elektra ir kanalizacija;</li>
                  <li>- surenkamoji gamyba mūsų gamybinėje salėje, kuri leidžia padidinti pastato kokybę, išvengiant oro sąlygų įtakos konstrukcijos konstrukcijai statybos metu;</li>
                  <li>- namo priežiūros išlaidų sumažinimas dėl energiją taupančios karkasinių namų technologijos;</li>
                  <li>- galimybė gaminti namus serijiniu būdu;</li>
                  <li>- daugkartinio pastato poslinkio galimybė eksploatacijos metu.</li>
                </ul>
              </div>
            </p>
            <a href="/product/21" class={" sm:text-md md:text-xl inline-block m-auto mt-6 w-64 text-white rounded-md border-2 border-white p-4 hover:bg-green-800 duration-500"}>Išsamiau</a>
          </div>
        </div>
        <div className="xl:w-2/3"></div>
      </section>
      <div className={"pt-10 sm:pt-0 min-h-96 " + color4}>
        <div className="flex h-4/5 max-w-screen xl:flex-row flex-col items-stretch">
          <img className="object-contain h-full xl:w-2/3" src='https://www.bustlanga.lt/1-1(1).webp' alt="Antras mobilus namelis"></img>
          <div className={"shadow-md flex justify-center align-middle bg-black bg-opacity-5 w-full 2xl:w-1/3 grow-0 "}>
            <div className="  m-auto">
              <div>
                <h1 className="p-10 pb-2 text-xl xl:text-4xl text-center">Namelis su terasa - variantas 2</h1>
                <p className="border-l-8 border-black p-10 xl:pt-10 inline-block align-middle text-md md:text-lg/8 xl:text-md/8 2xl:text-2xl/10 text-justify bg-gray-300">Premium segmento gyvenamasis namas.
                  Šis modelis yra idealus vienos šeimos namas. Apšiltintas 2 modulių namas pranoksta visus
                  mobiliųjų namų savininkų lūkesčius. Mūsų klientai liko patenkinti šiuo sprendimu, o mes
                  didžiuojamės, kad mums pavyko įgyvendinti jų norus.</p>
                <div className="w-full flex justify-center sm:text-md md:text-xl pb-4">
                  <a href="/product/22" class={"m-10 mt-0 inline-block m-auto mt-6 w-64 text-black rounded-md border-2 border-black p-4 hover:bg-green-700 duration-500 text-center"}>Išsamiau</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="object-scale-down bg-cover bg-no-repeat flex flex-col min-h-screen" style={{
        backgroundImage: `url("https://www.bustlanga.lt/pierre-chatel-innocenti--Na1sUQBYdw-unsplash.webp")`
      }}>
        <div className="h-screen flex">
          <div className="p-12 2xl:w-1/3 bg-black bg-opacity-30 hover:bg-opacity-50 duration-1000 flex justify-center flex-col">
            <div className="m-auto mb-10 xl:mr-12 xl:ml-12 xl:mb-12">
              <p className="text-white text-justify text-md md:text-lg xl:text-xl">
              Ieškote langų, kurie atspindi jūsų skonį? Mes turime. Norite mobilios namų erdvės, kuri atitiktų jūsų poreikius? Taip pat pas mus. Durys, kurios ne tik atitinka jūsų saugumo reikalavimus, bet ir išsiskiria iš aplinkinių? Mes tai galime suteikti. <br></br><br></br> Mūsų misija - ne tik parduoti produktus, bet ir įkvėpti jus kurti gyvenimo erdvę, kuri atspindėtų jūsų gyvenimo būdą ir vertybes. Esame čia, kad padėtume jums įgyvendinti tai, kuo gyvename.<br></br><br></br> Gaukite asmeninį pasiūlymą tinkintą pagal jūsų poreikius. 
              </p>
            </div>
            <div className="m-auto mt-0 sm:text-md md:text-xl">
              <Link to="contacts" className={"text-white rounded-md border-2 border-white p-4 hover:bg-green-800 duration-500"}>Gauti pasiūlymą</Link>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default About;