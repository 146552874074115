import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const HtmlToReactParser = require('html-to-react').Parser;


const IP = 'www.bustlanga.lt';

const color1 = "bg-[#7D8F69]";
const color2 = "bg-[#557153]";
const color3 = "bg-[#A9AF7E]";
const color4 = "bg-sky-50";
const color5 = "bg-blue-100";
const color6 = "bg-[#E6E5A3]";
const color7 = "bg-white";

const htmlToReactParser = new HtmlToReactParser();

const SingleProduct = () => {
    const [formattedDiv, setFormattedDiv] = useState([]);
    const item_id = window.location.pathname.split("/").pop();
    const [loading, setLoading] = useState(true);
    var item;

    const FetchData = () => {
        axios.get(`https://${IP}/api/productinfo/${item_id}/`)
            .then(response => {
                item = JSON.parse(response.data.replaceAll("'", '"'));
                item.aprasymas = item.aprasymas.replaceAll("\\&", '"');
                item.aprasymas = item.aprasymas.replaceAll("“", '"');
                item.aprasymas = item.aprasymas.replaceAll("\\n", '<br>');
                item.aprasymas = item.aprasymas.replaceAll("\\r", '');
                item.aprasymas = item.aprasymas.replaceAll("\\t", '');

                document.title = `${decodeURIComponent(item.pavadinimas)}`;
                const template =
                    <div className={`${color1} sm:p-16 md:p-32 mt-32 pt-16 min-h-screen`}>
                        <div class="mx-auto bg-white md:rounded-lg shadow-lg overflow-hidden w-4/5">
                            <div class="md:flex">
                                <div class="p-8">
                                    <a href="#" class="block mt-1 text-3xl leading-tight font-medium text-black hover:underline ml-2">{item.pavadinimas}</a>
                                    <Link to={`/products/${item.kategorija}`} class="inline-block bg-gray-200 rounded-full px-3 py-1 m-2 text-sm font-semibold text-yellow-400 bg-gray-600 ml-0">{item.kategorija}</Link>
                                    {item.subkategorija !== "Be subkategorijos" ? <Link to={`/subcategory/${item.subkategorija}`} class="inline-block bg-gray-200 rounded-full px-3 py-1 m-2 text-sm font-semibold text-yellow-400 bg-gray-600 ml-0">{item.subkategorija}</Link> : null}
                                    <p class="mt-2 text-gray-500 ml-2 xl:text-xl">{htmlToReactParser.parse(item.aprasymas)}</p>
                                </div>
                            </div>
                            <div className='flex justify-center flex-row flex-wrap'>
                                {
                                    item.nuotraukos.map(photo => (
                                        <img className="m-12 bg-gray-300 object-contain border-t-4 w-96 border-yellow-500 h-96" onClick={() => { const w = window.open(`${photo !== 0 ? `${photo}` : "/interrogation.png"}`) }} src={photo !== 0 ? `${photo}` : "interrogation.png"} alt={`${decodeURIComponent(item.pavadinimas)}`} />
                                    ))
                                }
                            </div>
                        </div></div>
                setLoading(false);
                setFormattedDiv(template);
            })
            .catch(error => {
                setLoading(false);
                console.log("Failed to fetch data. Reload page.");
                setFormattedDiv(<div className={`${color1} sm:p-16 md:p-32 mt-32 pt-16 min-h-screen justify-center flex`}>
                    <div className={" " + color2 + " bg-opacity-50 p-8 border-t-2 border-b-2 sm:rounded-md shadow-lg text-gray-800 sm:border-2 border-gray-400 w-full xl:w-2/5 m-auto"}>
                        <p className="text-xl font-extrabold leading-tight text-white text-center">Šis produktas nerastas</p>
                    </div>
                </div>)
            });
    }

    useEffect(() => {
        FetchData();
    }, []);

    if (loading) {
        return <div className={`${color1} sm:p-16 md:p-32 mt-32 pt-16 min-h-screen justify-center flex`}>
            <div className={" " + color2 + " bg-opacity-50 p-8 border-t-2 border-b-2 sm:rounded-md shadow-lg text-gray-800 sm:border-2 border-gray-400 w-full xl:w-2/5 m-auto"}>
                <p className="text-xl font-extrabold leading-tight text-white text-center">Kraunama...</p>
            </div>
        </div>
    }
    else return formattedDiv;
}

export default SingleProduct;