import { useEffect, useState } from "react"
import axios from "axios"
const HtmlToReactParser = require('html-to-react').Parser;


const IP = 'www.bustlanga.lt';

const color1 = "bg-[#7D8F69]";
const color2 = "bg-[#557153]";
const color3 = "bg-[#A9AF7E]";
const color4 = "bg-sky-50";
const color5 = "bg-blue-100";
const color6 = "bg-[#E6E5A3]";
const color7 = "bg-white";

const htmlToReactParser = new HtmlToReactParser();

const Home = () =>{
    const [formattedList, setFormattedList] = useState([]);

    useEffect(()=>{
        fetchData();
        document.title = 'Bustlanga - Naujienos';
    }, []);

    const fetchData = () => {
        axios.get(`https://${IP}/api/news/`)
            .then(response => {
                var splitString;
                try{
                    const list = response.data; 
                    splitString = list.split('}{');
                    for(var i = 0; i < splitString.length; i++){
                        splitString[i] = splitString[i].replaceAll("'", '"');
                        if(splitString.length !== 1){
                            if(i === 0 && splitString.length != 1){
                                splitString[i] = splitString[i] + '}';
                            }
                            else if(i === splitString.length - 1 && splitString.length != 1){
                                splitString[i] = '{' + splitString[i];
                            }
                            else{
                                splitString[i] = '{' + splitString[i] + '}';
                            }
                            splitString[i] = JSON.parse(splitString[i]);
                        }
                        else{
                            splitString[i] = JSON.parse(splitString[i]);
                        }
                    }
                }
                catch(err){
                    //console.log(err);
                }

                splitString.forEach((item) => {
                    item.tekstas = item.tekstas.replaceAll("\\&", '"');
                    item.tekstas = item.tekstas.replaceAll("\\n", '<br>');
                    item.tekstas = item.tekstas.replaceAll("“", '"');
                    item.tekstas = item.tekstas.replaceAll("\\r", '');
                    item.tekstas = item.tekstas.replaceAll("\\t", ' ');
                });
                const formattedList = splitString.map(item => (

                    <div class="max-screen-2xl mx-auto mt-10 xl:p-12 xl:py-0">
                        <div class="bg-white overflow-hidden shadow-md rounded-lg flex border-2 border-green-600">
                            <div class="hyphens-auto break-words w-full p-6">
                                <h2 class="text-2xl font-semibold text-gray-800 mb-2">{item.pavadinimas}</h2>
                                <p class="text-gray-600 text-sm mb-4">{item.data}</p>
                                <p class="text-gray-700">{htmlToReactParser.parse(item.tekstas)}</p>
                            </div>
                        </div>
                    </div>

                    
                ));
                setFormattedList(formattedList);    
            })
            .catch(error => {
                console.log("Failed to fetch data. Reload page.");
        });
    };
    return(
        <div className={`pt-48 ${color1} bg-gray-200 min-h-screen flex justify-center flex-col items-stretch`}>
            <div className={` p-6`}>
                {formattedList}
            </div>
        </div>
    )
}

export default Home;