import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './pages/Layout';
import Empty from './pages/Empty';
import Home from './pages/Home';
import News from './pages/News';
import Products from './pages/Products';
import Contacts from './pages/Contacts';
import SingleProduct from './pages/SingleProduct';
import ProductsFiltered from './pages/ProductsFiltered';
import SubcatFiltered from './pages/SubcatFilter';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<Home/>}></Route>
          <Route path="news" element={<News/>}></Route>
          <Route path="products" element={<Products/>}></Route>
          <Route path="product/:prodID" element={<SingleProduct/>}></Route>
          <Route path="products/:category" element={<ProductsFiltered/>}></Route>
          <Route path="subcategory/:subcat" element={<SubcatFiltered/>}></Route>
          <Route path="contacts" element={<Contacts/>}></Route>
        </Route>
        <Route path="*" element={<Empty/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;