function App() {
    return (
        <div className="bg-gradient-to-r from-cool-gray-500 to-warm-gray-500 h-screen flex items-center justify-center">
            <div className="relative bg-gray-200 bg-opacity-50 p-8 border-t-2 border-b-2 sm:rounded-md shadow-lg text-gray-800 sm:border-2 border-gray-400">
                <p className="text-3xl font-extrabold leading-tight text-gray-700">Apgailestaujame, serveris nepasiekiamas</p>
                <p className="pt-3 pl-3">Galimi atvejai:</p>
                <ul className="pl-5" type="1">
                    <li>Vyksta atnaujinimo darbai</li>
                    <li>Serveris iš tikro nulūžo</li>
                </ul>
                <p className="pt-6 pl-3">Prašome palaukti, kol viskas bus sutvarkyta</p>
            </div>
        </div>
    );
}

export default App;