import axios from 'axios';
import { useState, useEffect } from 'react';


const color1 = "bg-[#7D8F69]";
const color2 = "bg-[#557153]";
const color3 = "bg-[#A9AF7E]";
const color4 = "bg-sky-50";
const color5 = "bg-blue-100";
const color6 = "bg-[#E6E5A3]";
const color7 = "bg-white";

const IP = 'www.bustlanga.lt';

const Contacts = () => {

    const [vardas, setVardas] = useState();
    const [pastas, setPastas] = useState();
    const [tekstas, setTekstas] = useState();

    useEffect(()=>{
        document.title = 'Bustlanga - Kontaktai';
    }, []);

    const postData = () => {
        axios.get(`https://${IP}/api/question/`, {
            params: {
                vardas: vardas,
                pastas: pastas,//document.getElementById("pastas").value,
                tekstas: tekstas//document.getElementById("tekstas").value
            }
        })
            .then(response => {
                if (response.data == "OK")
                    alert("Užklausa priimta.")
                else if (response.data == "DUP") {
                    alert("Šiuo paštu jau užklausa pateikta. Palaukite, kol jums bus atsakyta.")
                }
                else {
                    alert("Užklausos nepavyko pateikti. Įsitikinkite, kad suvestas tinkamas el. paštas ir kiti laukai nėra tušti.")
                }

            })
            .catch(error => {
                console.log("Failed to fetch data. Reload page.");
            });
    };
    return (
        <div className={"flex justify-center min-h-screen " + color1 + " mt-10 md:mt-0"}>
            <div class={`m-auto max-w-4xl mx-auto p-8 ${color7} mt-32 xl:mt-64`}>
                <h1 class="text-3xl font-semibold mb-4">Susisiekite</h1>

                <div class="flex flex-col md:flex-row">
                    <div class="w-full md:w-1/2 mr-0 md:mr-4">
                        <h2 class="text-xl font-semibold mb-2">Kontaktinė informacija</h2>
                        <p class="mb-4">UAB <span class="font-semibold">BUSTLANGA</span></p>
                        <p><span class="font-semibold">Įmonės kodas:</span></p>
                        <p class="mb-4">302928399</p>
                        <p><span class="font-semibold">PVM mokėjimo kodas:</span></p>
                        <p class="mb-4">LT100007377412</p>
                        <p class="mb-4"><span class="font-semibold">Adresas:</span> Vingio g. 16, LT-92334 Klaipėda</p>
                        <p class="mb-4"><span class="font-semibold">Mobilus tel. nr.:</span> +370 601 92900</p>
                        <p class="mb-4"><span class="font-semibold">Banko sąskaita:</span> LT664010042301481916</p>
                        <p class="mb-4"><span class="font-semibold">El. paštas:</span> bustlanga@gmail.com</p>
                        <p class="mb-4"><span class="font-semibold">Tinklapis: </span> <a class="text-cyan-600" href="https://www.bustlanga.lt">www.bustlanga.lt</a></p>
                    </div>

                    <div class="w-full md:w-1/2">
                        <h2 class="text-xl font-semibold mb-2">Parašykite mums</h2>
                        <form onSubmit={postData}>
                            <div class="mb-4">
                                <label for="name" class="block text-gray-700 font-semibold mb-2">Vardas</label>
                                <input value={vardas} onChange={(e) => setVardas(e.target.value)} type="text" id="name" name="name" class="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:border-indigo-500" placeholder="Jūsų vardas"/>
                            </div>
                            <div class="mb-4">
                                <label for="email" class="block text-gray-700 font-semibold mb-2">Paštas</label>
                                <input  value={pastas} onChange={(e) => setPastas(e.target.value)} type="email" id="email" name="email" class="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:border-indigo-500" placeholder="Jūsų paštas"/>
                            </div>
                            <div class="mb-4">
                                <label for="message" class="block text-gray-700 font-semibold mb-2">Žinutė</label>
                                <textarea value={tekstas} onChange={(e) => setTekstas(e.target.value)} id="message" name="message" rows="4" class="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:border-indigo-500" placeholder="Tekstas"></textarea>
                            </div>
                            <button onClick={postData} type="button" class={`${color2} text-white font-semibold px-4 py-2 rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600`}>Siųsti</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacts;